<template>
	<!-- 메인 -->
	<div class="admin-content">
		<h3>배너관리</h3>
		<div class="flex-wrap right">
			<div class="ml10">
				<div class="btn-wrap">
					<button class="btn-box" @click="methods.getBannerInfoList">조회</button>
					<button class="btn-box green" @click="{
						state.dialog.isOpen = true;
						state.dialog.isNew = true;
					}" >신규</button>
				</div>
			</div>
			<div>
                <div class="sch-wrap3">
                    <div>
                        <label>계정 유형</label>
                        <div class="select-box">
                            <select id="selectSystCode" v-model="state.dataTable.selectedSystemCode"/>
                        </div>
                    </div>
                </div>
            </div>
		</div>

		<!-- 총 개수 -->
		<div class="total-box">
			<p>Total <strong>{{state.dataTable.bannerItems === null ? 0 : state.dataTable.bannerItems.length}}</strong></p>
		</div>

		<DataTable 
			class="p-datatable-sm" 
			columnResizeMode="fit"
			scrollHeight="497px"
			:value="state.dataTable.bannerItems"
			:resizableColumns="true"
			:rowHover="true"
			@row-click="eventHandler.dataTable.onRowClick">

			<template #empty>
                데이터가 없습니다.
            </template>
			
			<Column field="SystCode" header="시스템코드" :sortable="true" style="width:200px" headerClass="tc"></Column>
			<Column field="BannerName" header="배너명" :sortable="true" style="width:400px" headerClass="tc"></Column>
			<Column field="BannerPath" header="배너 파일 경로"  :sortable="true" style="width:400px" headerClass="tc"></Column>
			<Column field="Description" header="설명" :sortable="true" style="width:400px" headerClass="tc"></Column>
			<Column field="ContentMaintain" header="유지기간" style="text-align:center; width:200px;"
				:sortable="true" headerClass="tc"></Column>

		</DataTable>

		<!-- 상세 -->
		<Dialog 
			:header="state.dialog.header" 
			:visible="state.dialog.isOpen"
			:modal="true"
			@show="eventHandler.dialog.show"
			@hide="eventHandler.dialog.hide" 
			@close="state.dialog.isOpen = false"
			@update:visible="state.dialog.isOpen = false">

			<div class="dialog-wrap1">
				<div>
    	            <label>배너명 <i class="required">*</i></label>
    	            <div>
						<input class="input-text mr10" id="bannerName" ref="bannerName" type="text" v-model.trim="state.dataTable.rowDataModel.BannerName" :disabled="!state.dialog.isNew" maxlength="128" />
    	            </div>
    	        </div>
				<div>
    	            <label>배너설명</label>
    	            <div>
						<input class="input-text mr10" id="description" type="text" v-model.trim="state.dataTable.rowDataModel.Description"  maxlength="256" />
    	            </div>
    	        </div>
				<div>
    	            <label>배너경로 <i class="required">*</i></label>
    	            <div>
						<input class="input-text mr10" id="bannerPath" ref="bannerPath" type="text" v-model.trim="state.dataTable.rowDataModel.BannerPath" :disabled="!state.dialog.isNew" maxlength="128" />
    	            </div>
    	        </div>
				<div>
    	            <label>유지기간 <i class="required">*</i></label>
    	            <div>
						<input class="input-text mr10" ref="contentMaintain" type="date" id="icon" v-model="state.dataTable.rowDataModel.ContentMaintain" :showIcon="true"
						aria-label="Date" @date-select="eventHandler.dialog.dateTimeFormat" />                </div>
    	        </div>
			</div>
			<br>
			<div class="flex-wrap right">
				<div class="ml10">
				  <div class="btn-wrap">
					<button v-if="!state.dialog.isNew" class="btn-box gray" @click="methods.bannerValidation" >수정</button>
					<button v-else class="btn-box gray" @click="methods.bannerValidation" >저장</button>
					<button class="btn-box red" @click="methods.deleteBannerInfo" >삭제</button>
				  </div>
				</div>
			</div>
		</Dialog>
	</div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { banner } from '@/modules/system/PCM_SM0660E.js';
import { common } from '@/modules/common/common.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
export default {
	components: {
	},
	setup() {

		// 스토어
		const store = useStore();

		// 유효성 검사 ref
        const bannerName = ref(); // 배너명
        const bannerPath = ref(); // 배너경로
        const contentMaintain = ref(); // 유지기간

		// state
		const state = reactive({
			// 데이터 테이블 state
			dataTable: {

				// 배너 목록
				bannerItems: null,

				// 선택된 시스템코드
				selectedSystemCode: "ADM",

				// 행 데이터 모델
				rowDataModel: new Object(),
			},

			dialog: {

				// 다이얼로그 헤더 텍스트
				header: "배너관리",

				// 다이얼로그 오픈여부
				isOpen: false,

				// 신규 여부
				isNew: false,
			},
		})

		// 메서드
		const methods = {
			// 배너정보 조회
			getBannerInfoList: () => {

				// 스토어 값 변경
				store.commit("setLoadingStatus", true);

				banner.getBannerInfoList(state.dataTable.selectedSystemCode)
					.then(result => state.dataTable.bannerItems = result)
					.finally(() => {

						// 스토어 값 변경
						store.commit("setLoadingStatus", false);
					});
			},

			// 배너정보 등록
			saveBannerInfo: () => {

				// 스토어 값 변경
				store.commit("setLoadingStatus", true);

				banner.saveBannerInfo(state.dataTable.rowDataModel).then((response) => {

					if (response.success) {

						alertManager.toast.add();

						// 목록 재조회
						methods.getBannerInfoList();
					}

				}).finally(() => {

					// 스토어 값 변경
					store.commit("setLoadingStatus", false);

					// 팝업 닫기
					state.dialog.isOpen = false;
				});
			
			},

			// 배너정보 수정
			updateBannerInfo: () => {

				// 스토어 값 변경
				store.commit("setLoadingStatus", true);
	
				banner.updateBannerInfo(state.dataTable.rowDataModel).then((response) => {
	
					if (response.success) {
	
						alertManager.toast.save();
	
						// 목록 재조회
						methods.getBannerInfoList();
					}
	
				}).finally(() => {
	
					// 스토어 값 변경
					store.commit("setLoadingStatus", false);
	
					// 팝업 닫기
					state.dialog.isOpen = false;
				});	

			},

			// 배너정보 삭제
			deleteBannerInfo: () => {

				alertManager.confirm.remove().then(() => {
					// 스토어 값 변경
					store.commit("setLoadingStatus", true);

					banner.deleteBannerInfo(state.dataTable.rowDataModel).then((response) => {

						if (response.success) {

							alertManager.toast.remove();

							// 목록 재조회
							methods.getBannerInfoList();
						}

					}).finally(() => {

						// 스토어 값 변경
						store.commit("setLoadingStatus", false);

						// 팝업 닫기
						state.dialog.isOpen = false;
					});
				})

			},

			// 배너 정보 유효성검사
            bannerValidation: () => {

                let validator = state.dataTable.rowDataModel;
                
                // 배너명
                if (!validator.BannerName) {

                    bannerName.value.focus();
                    alertManager.alert.warning('배너명을 입력해주세요');
                }
                // 배너경로
                else if (!validator.BannerPath) {

                    bannerPath.value.focus();
                    alertManager.alert.warning('배너경로를 입력해주세요');
                }
                // 유지기간
                else if (!validator.ContentMaintain) {

                    contentMaintain.value.focus();
                    alertManager.alert.warning('유지기간을 입력해주세요');
                }
                else {

                    // 배너 상세 저장
                    if(state.dialog.isNew) alertManager.confirm.save().then(() => { methods.saveBannerInfo() });
                    else alertManager.confirm.edit().then(() => { methods.updateBannerInfo() });
                }
            },
		}

		// 이벤트 핸들러
		const eventHandler = {

			// 데이터 테이블 관련 이벤트 핸들러
			dataTable: {

				// 행 클릭 이벤트 처리
				onRowClick: event => {

					// 행 데이터 모델 설정
					state.dataTable.rowDataModel = Object.assign({}, event.data);

					// 팝업 오픈
					state.dialog.isOpen = true;
				},
			},
			// 다이얼로그 관련 이벤트 핸들러
			dialog: {

				// show 이벤트 처리
				show: () => {

					// 신규 다이얼로그 모드
					if (state.dialog.isNew) {

						state.dataTable.rowDataModel.SystCode = state.dataTable.selectedSystemCode;
					}
				},

				// hide 이벤트 처리
				hide: () => {

					// 행 데이터모델 초기화
					state.dataTable.rowDataModel = new Object();
					state.dialog.isNew = false;
				},

				// DateTime 변환 이벤트 처리
				dateTimeFormat: (value) => {
					const dayjs = require('dayjs');
					state.dataTable.rowDataModel.ContentMaintain = dayjs(value).format('YYYY-MM-DD');
				}
			},
		}

		onMounted(() => {

			// 시스템 코드 초기조회
			common.getSystemCodeInfo('SYSTEM', '')
				.then(result => {
					const obj = document.getElementById("selectSystCode");
					obj.options.length = 0;
					for (const key in result) {
						if (typeof(result[key]) === 'object')
						obj.options[obj.options.length] = new Option(result[key]["CodeName"], result[key]["CodeValue"]);
					}
				});


			// 배너 정보 초기조회
			methods.getBannerInfoList();
		})

		return {
			state,
			banner,
			common,
			methods,
			eventHandler,
			bannerName,
			bannerPath,
			contentMaintain,
		}
	}
};
</script>