import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 배너 화면 관련 기능 클래스
 */
class Banner {

    /**
     * 생성자
     */
    constructor() {
    }

    /**
     * 배너 정보 조회
     * @param {String} systCode 
     * @returns 
     */
    getBannerInfoList(systCode) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz';
            request.methodId = 'GetBannerInfo';
            request.parameters.systCode = systCode

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 배너 정보 등록
     * @param {Any} detail 
     * @returns 
     */
    saveBannerInfo(detail) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz'
            request.methodId = 'CreateBannerInfo'
            request.parameters = detail

            service.execute(request).then(response => {

                resolve(response.data)

            }).catch(error => reject(error));
        });
    }

    /**
     * 배너 정보 수정
     * @param {Any} detail 
     * @returns 
     */
    updateBannerInfo(detail) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz'
            request.methodId = 'UpdateBannerInfo'
            request.parameters = detail

            service.execute(request).then(response => {

                resolve(response.data)

            }).catch(error => reject(error));
        });
    }

    /**
     * 배너 정보 삭제
     * @param {Any} detail 
     * @returns 
     */
    deleteBannerInfo(detail) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz'
            request.methodId = 'DeleteBannerInfo'
            request.parameters = detail

            service.execute(request).then(response => {

                resolve(response.data)
                
            }).catch(error => reject(error));
        });
    }
}

export const banner = new Banner();